@media (min-width:1200px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl 
	{ 
		/* max-width: 62.5rem;  */
		max-width: 80% ;
		margin-left: 235px;
		margin-top: 20px;

	}
/* 
	.newsfeed-ui
	{
	
		margin-left: 270px;
	} */
}

.btn.btn-primary {
border-color: #2c4f69 !important;
opacity: 0.9 !important;
border-radius: 5px;
}

/* .card {
	box-shadow: 0px 1px 1px rgba(40, 40, 40, 0.2);
	border-radius: 5px;
  }
   */
  