.infographic {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* margin-left: 50px; */
  }
  
  .goal-card {
    width: 200px;
    height: 200px;
    margin: 10px;
    border-radius: 10%;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: transform 0.3s;
  }
  
  .goal-card:hover {
    transform: scale(1.05);
  }
  
  .goal-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .goal-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0px;
    background-color: #fff;
    color: #fff;
    text-align: center;
    transition: opacity 0.3s;
    opacity: 0;
  }
  
  .goal-card:hover .goal-content {
    opacity: 1;
  }
  
  .goal-title {
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  .goal-description {
    font-size: 14px;
  }
  
  /* Add any additional styles or animations as needed */
  
  .infographic {
    padding: 10px;
    background-color: #f5f5f5;
  }
  
  .heading-block {
    margin-bottom: 20px;
  }
  
  .title {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
  p {
    line-height: 1.5rem !important;
    margin-bottom: 0.5rem !important;
    color: #555;
  }
  
  .tabscontent
  {
    justify-content: flex-start !important;
  }
  
  /* .container {
    padding: 20px;
    background-color: #fff;
  } */
  
  .row.justify-content-between {
    margin-right: -15px;
    margin-left: -15px;
  }
  
  .col-12,
  .col-sm-6,
  .col-lg-4 {
    padding-right: 15px;
    padding-left: 15px;
  }
  
  
  
      .playlist-container {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
       
          animation: scroll 50s linear infinite; /* Adjust the timing as needed */
        }
        
        /* Define a keyframe animation for moving items up */
@keyframes move-up {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  
  /* Apply the animation to the playlist container */
  .playlist-content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    animation: move-up 50s linear infinite; /* Adjust the timing as needed */
  }
  
        

        .playlist-item {
          width: 100%;
        }
        
        @keyframes scroll {
          0% {
            transform: translateY(0);
          }
          100% {
            transform: translateY(calc(-100% - 100px)); /* Stop 100px above the container bottom */
          }
        }
        
        @keyframes move-up {
          0% {
            transform: translateY(0);
          }
          100% {
            transform: translateY(calc(-100% - 100px)); /* Match the scroll distance */
          }
        }

        @keyframes move-left {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(calc(-100% - 10px)); /* Move items to the left */
          }
        }
  
        /* Add hover and focus styles to the playlist items */
  .playlist-item {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      transition: background-color 0.3s ease; /* Smooth transition on hover/focus */
    }
    
    .playlist-item:hover,
    .playlist-item:focus {
      background-color: #b4c746; /* Change the background color on hover/focus */
      cursor: pointer; /* Show a pointer cursor on hover/focus */
      outline: none; /* Remove the default focus outline */
    }
    
  
    .color-pad
    {
      background: rgba(255, 255, 255, 0) !important;
    }
        
  
    #video-plays{
  
      height: 500px;
    }

    @media (max-width: 768px) {
      .playlist-container {
        flex-direction: row; /* Display items in a vertical column */
    height: auto; /* Allow content to determine the height */
    overflow-x: auto; /* Hide horizontal overflow */
    animation: none;
      }
    
      .playlist-content {
        display: flex;
        flex-direction: row; /* Display items in a horizontal row */
        overflow-x: auto; /* Enable horizontal scrolling */
        animation: move-left 50s linear infinite; /* Apply the horizontal animation */
        width: 100%; /* Ensure full width */
        height: auto; /* Allow content to determine the height */
      }
    
      /* Styles for each playlist item in the row */
      .playlist-item {
        min-width: 200px; /* Adjust the width of each item as needed */
        margin-right: 10px; /* Add spacing between items */
      }
    }

    

    
    
    
    
    
    