.camp-status {
  &.null {
    background-color: rgb(104 148 221);
  }
  &.pending {
    background-color: rgb(196, 102, 14);
  }
  &.publish {
    background-color: rgb(29, 123, 8);
  }
}
