.doner-info {
  display: flex;
  margin-bottom: 20px;
}
.doner-info .icon {
  width: 40px;
  height: 40px;
  background-color: #e6f6ef;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  margin-right: 15px;
  color: #0d8e52;
}
.doner-info .content .title {
  font-size: 16px;
  font-weight: 600;
}

/* count down styles start */
.single-count-down-item {
  width: calc(100% / 4);
  background-color: #fcb11a;
  color: rgba(255, 255, 255, 0.8);
  margin: 6px;
  border-radius: 3px;
  text-align: center;
  padding: 10px 10px;
}
.single-count-down-item > span {
  &.time {
    font-size: 19px;
    font-weight: 700;
    line-height: 20px;
  }
  &.label {
    display: block;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }
}
.expired-container {
  background-color: #e10f0fe6;
  color: rgba(255, 255, 255, 0.8);
  font-size: 15px;
  border-radius: 3px;
  font-weight: 700;
  border-radius: 12px;
  padding: 4px 8px;
  display: inline-block;
}
/* count down styles end */
.campaignDesc {
  font-family: "Varela Round", sans-serif;
  font-size: 14px;
  color: #444;
  line-height: 24px;
  margin-top: 5px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.campaignDesc:hover {
  text-decoration: underline;
}
