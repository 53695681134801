.image-wrapper {
  position: relative;
}
.image-wrapper img {
  height: 90px;
}

@media (min-width: 640px) {
  .image-wrapper img {
    height: 130px;
  }
}
@media (min-width: 1024px) {
  /* Set height for large devices */
  .image-wrapper img {
    height: 150px;
  }
}
.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
}
