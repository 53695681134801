.gallery {
  -webkit-column-count: 5;
  -moz-column-count: 5;
  column-count: 5;
  -webkit-column-width: 20%;
  -moz-column-width: 20%;
  column-width: 20%;
  padding: 0 10px;
  width: 100%;
}
.gallery .pics {
  position: relative;
  margin-bottom: 8px;
  text-align: center;
  transition: all 350ms ease;
  color: white;
  cursor: pointer;
}
.pics {
  /* position: relative;
  margin-bottom: 8px;
  text-align: center; */
  transition: all 350ms ease;
  color: white;
  cursor: pointer;
}
.gallery .pics:hover {
  filter: opacity(0.8);
}
.selected {
  border: 1px white;
  filter: brightness(40%);
}
.tick-mark {
  /* position: fixed; */
  /* top: 10px;
  right: 10px;
  font-size: 10px; */
  /* color: #fff;
  border-radius: 10%; */
  position: absolute;
  top: 50%;
  left: 40%;
  padding: 4px;
  color: aliceblue;
  background-color: rgba(48, 3, 250, 0.5);
  transform: translate(-50%, -50%);
}
.centered {
  position: absolute;
  top: 80%;
  left: 50%;
  padding: 5px;
  background-color: rgba(2, 0, 10, 0.5);
  transform: translate(-50%, -50%);
}
@media (max-width: 991px) {
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}
@media (max-width: 400px) {
  .gallery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 10%;
    -moz-column-width: 10%;
    column-width: 10%;
  }
}
