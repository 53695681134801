@media (min-width: 576px) {
  .form1 {
    margin: 5px;
  }
}
@media (min-width: 768px) {
  .form2 {
    margin: 25px;
  }
}

.btn-style1 {
  border-radius: 15px;
}

/* spinner styles */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 16000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #00ff22;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
/* end */

/* button style  */
.my-button {
  border: none;
  cursor: pointer;
  background-color: transparent !important;
  color: #007bff;
  /* font-weight: bold; */
  transition: all 0.2s ease-in-out;
}
.my-button span
{
  /* a { */

/* } */
  font-size: 15px;
}

.my-button span:hover{
/* a:hover { */
/* } */
text-decoration: underline;
}
.my-button:hover {
  background-color: transparent !important;
  color: #007bff !important;
}

/* tab styles */
@media screen and (max-width: 992px) {
  .user-tabs {
    display: none;
  }
}
.active-tab {
  border-radius: 5px;
  background-color: #50b5ff;
}
.active-tab span,
.active-tab .icon {
  color: white;
}
/* end */
.react-icon-btn {
  cursor: pointer;
}

/* common styles */
.text-underLine {
  color: #007bff;
  &:hover {
    text-decoration: underline;
  }
}
