@media (min-width: 768px) {
  .lightbox-slide-btn {
    padding: 10px;
  }
}
/* lightbox style  start*/
.lightbox-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 8px;
  background: rgba(35, 35, 35, 0.65);
  border: none;
  font-size: 20px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.prev-button {
  left: 20px;
}
.next-button {
  right: 20px;
}
.close-button {
  top: 40px;
  right: 40px;
  padding: 8px;
  border-radius: 6px;
  font-size: 16px;
}
.text {
  top: 40px;
  left: 40px;
  padding: 8px;
  font-size: 16px;
  border-radius: 6px;
  color: antiquewhite;
}
/* lightbox style end */
/* gallery style start */
.galleryWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* align-items: center; */
  /* justify-content: center; */
  /* max-width: 620px; */
  margin: 0 auto;
}

.galleryWrap .single {
  width: 200px;
  height: 100px;
  cursor: pointer;
}

.galleryWrap .single img {
  max-width: 100%;
}

.galleryWrap .single img:hover {
  transform: scale(1.02);
}

.sliderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.fullScreenImage {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullScreenImage img {
  max-width: 100%;
  max-height: 80vh;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
