/* basic styles */
.skeleton {
  background: #ddd;
  overflow: hidden;
  margin: 10px 0;
  border-radius: 4px;
}
.skeleton.text {
  width: 100%;
  height: 15px;
}
.skeleton.progressbar {
  width: 50%;
  height: 10px;
  margin-bottom: 10px;
}
.skeleton.avatar {
  width: full;
  height: 100px;
}
.skeleton.button {
  width: full;
  height: 20px;
  border-radius: 10px;
}
.skeleton.thumbnail {
  width: 100px;
  height: 100px;
}
.skeleton-wrapper {
  margin: 20px auto;
  padding: 10px 15px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

/* skeleton profile */
.skeleton-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* grid-template-columns: 1fr 2fr; */
}

/* themes */
.skeleton-wrapper.light {
  background: #f2f2f2;
}
.skeleton-wrapper.dark {
  background: #444;
}
.skeleton-wrapper.dark .skeleton {
  background: #777;
}

/* animation effects */
.shimmer-wrapper {
  position: absolute;
  /* parent must be relative position & overflow hidden */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: loading 2.5s infinite;
}
.shimmer {
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: skewX(-20deg);
  box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.2);
}
.dark .shimmer {
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
}

@keyframes loading {
  0% {
    transform: translateX(-150%);
  }
  50% {
    transform: translateX(-60%);
  }
  100% {
    transform: translateX(150%);
  }
}
