/* Card.css */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap');

.friendscard-body {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Raleway', sans-serif;
  background-color: rgb(240, 240, 240);
  padding: 20px;
  box-sizing: border-box;
}

.friendscard {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 250px;
  padding: 15px;
  height: 210px;
  background-color: white;
  margin-top: 30px;
}

.ln-friendscard {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 260px;
  padding: 15px;
  height:auto;
  background-color: white;
  margin-top: 30px;
}

.ln-donatecard {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 260px;
  padding: 15px;
  height:auto;
  background-color: white;
  margin-top: 30px;
}

.friendscard img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-image: linear-gradient(60deg, #2AAA8A, #4169E1);
  padding: 5px;
  margin-top: -45px;
}


.cont {
  width: 90%;
  height: 0;
  overflow: hidden;
  text-align: center;
  transition: all 0.6s ease;
}

.friendscard .cont p {
  margin-bottom: 10px;
}

.friendscard .cont .link i {
  font-size: 25px;
  color: rgb(82, 82, 82);
  transition: all 0.2s ease;
}

.friendscard .cont .link i:hover {
  color: black;
  cursor: pointer;
}

.friendscard:hover > .cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  height: 150px;
}

.friendscard:hover > .link {
  width: 40%;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 576px) {
  .friendscard {
    margin: 40px auto;
    flex-basis: calc(100% - 40px);
    max-width: calc(100% - 40px);
  }
}

.friendscard .organization-card-image
{
  margin-top: 0px !important;
}

.btn-custom
{
  margin-top: 5px;
}

.ln-donatecard .donate
{
height: auto !important;
border-radius: 0% !important;
margin-top:0px !important;
background-image: linear-gradient(60deg, #2AAA8A, #4169E1);
padding: 3px;
}

.ln-friendscard .obituary
{
height: 110px !important;
width: 110px !important;
border-radius: 0% !important;
margin-top:0px !important;
background-image: linear-gradient(60deg, #2AAA8A, #4169E1);
padding: 3px;
}
