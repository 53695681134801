/* FILE UPLOAD */

.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 180px; */
  border: 5px dashed rgb(129 142 191);
  padding: 1rem;
}
/* .active {
  background-color: whitesmoke !important;
} */
button {
  padding: 0.5rem;
  margin: 1rem;
  font-size: small;
  border: 1px solid gray;
  background-color: rgb(211 55 108);
  color: aliceblue;
  border-radius: 5px;
  cursor: pointer;
}
.note {
  color: red;
}
.fileName {
  color: #4368ad;
}
