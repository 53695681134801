/*!
*
* Template: SocialV - Responsive Bootstrap 5 Admin Dashboard Template
* Author: iqonic.design
* Design and Developed by: iqonic.design
* NOTE: This file contains the styling for responsive Template.
*
*/

@import "./custom/helper/fonts";

// Configuration
@import "./bootstrap/functions";
@import "./custom/variables";
@import "./custom/variables/index";
@import "./bootstrap/variables";
@import "./bootstrap/maps";
@import "./bootstrap/mixins";
@import "./bootstrap/utilities";

// Custom Mixin And Helper
@import "./custom/helper/functions";
@import "./custom/helper/mixins";

// Layout & components
@import "./custom/helper/root";
@import "./bootstrap/root";
@import "./bootstrap/reboot";
@import "./bootstrap/type";
@import "./bootstrap/images";
@import "./bootstrap/containers";
@import "./bootstrap/grid";
@import "./bootstrap/tables";
@import "./bootstrap/forms";
@import "./bootstrap/buttons";
@import "./bootstrap/transitions";
@import "./bootstrap/dropdown";
@import "./bootstrap/button-group";
@import "./bootstrap/nav";
@import "./bootstrap/navbar";
@import "./bootstrap/card";
@import "./bootstrap/accordion";
@import "./bootstrap/breadcrumb";
@import "./bootstrap/pagination";
@import "./bootstrap/badge";
@import "./bootstrap/alert";
@import "./bootstrap/progress";
@import "./bootstrap/list-group";
@import "./bootstrap/close";
@import "./bootstrap/toasts";
@import "./bootstrap/modal";
@import "./bootstrap/tooltip";
@import "./bootstrap/popover";
@import "./bootstrap/carousel";
@import "./bootstrap/spinners";
@import "./bootstrap/offcanvas";

// Helpers
@import "./bootstrap/helpers";

// Utilities
@import "./bootstrap/utilities/api";

// // Extra Components and Utilities and plugins
@import "./custom/variables/icon-size";
@import "./custom/components/components";
@import "./custom/helper/utilities";
@import "./custom/plugins/plugins";

@import "./custom/mode/dark/index";
@import "./custom/mode/rtl/index";



.schedule-btn {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #088dcd;
    font-size: 13px;
    padding: 2px 10px;
    text-transform: capitalize;
    text-align: center;
    cursor: pointer;
}
.datetimepicker input{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.8;
    color: #555770;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.313rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
