.dropdown-toggle-btn {
  cursor: pointer;
  color: deepskyblue;
  font-size: 28px;
  /* background: powderblue; */
  /* border-radius: 55%; */
  /* padding: 4px; */
}
.cover-image-container {
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
}

.cover-image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 400px) {
  .cover-image-container {
    width: 100%;
    height: 150px;
    overflow: hidden;
    position: relative;
  }
}
@media (min-width: 401px) and (max-width: 768px) {
  .cover-image-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
    position: relative;
  }
}
