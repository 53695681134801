.badge-container {
  background-color: #4caf50;
  /* background-color: #c02271; */
  border-radius: 12px;
  padding: 4px 8px;
  display: inline-block;
}

.badge-label {
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
}

.header-for-bg .data-block 
{
  background: rgba(255, 255, 255, 0);
  color: white;
}

@media (min-width:1200px) {
	.background-header
    {
      margin-left: 200px !important;
    }
}

.custom {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.card-img {
  grid-column: 1 / 2;
}

.card-table {
  grid-column: 2 / 3;
}

table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

th, td {
  padding: 10px;
  border: 1px solid #ccc;
}

.organization-modal-body{
  height: 90vh;
  overflow-y: scroll;
}

.img-wrapper{
  position: relative;
  box-shadow: inset 0 0 15px rgb(0 0 0 / 10%), inset 0 0 0 1px rgb(0 0 0 / 5%);
  background: #eee;
  cursor: pointer;
  width: 100px;
  height: 100px;
}
.select-img{
  height: 100px;
}

.video-img:after {
  content:url("https://www.wonderplugin.com/download/playbutton.png");
  z-index:999;
  position:absolute;
  top:50%;
  left:50%;
  margin-left:-32px;
  margin-top:-32px;
}
.cursor-pointer{
  cursor: pointer;
}
.see-more::before{
  content:"See more";
  z-index:999;
  position:relative;
  top:50%;
  left:50%;
  margin-left:-32px;
  margin-top:-32px;
  font-size: 24px;
  color: #eee;
}
.see-more{
  /* background-color: #42413e4b; */
  z-index: 10;
}
.img-contain{
  padding: 0px;
    background-color: #ebe8e4;
    border: solid 5px #eee6e000;
    border-radius: 5px;
}